import React, { useEffect } from "react";
import {
  Footer,
  Hero,
  MainContainer,
  Separator,
  StepContainer,
  StepIcon
} from "./styles/Tap2Promo";

import logo from "./img/logo.png";
import registerIcon from "./img/register-icon.svg";
import AppStoreLinks from "./AppStoreLinks";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getDeviceByModel } from "../../features/devices/deviceSlice";
import { getDasDeviceInfo } from "../../features/das-device-manager/dasDeviceManagerSlice";
import Spinner from "../../components/Spinner";

const Tap2Promo = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const { device: discoveryDevice, isLoading: isLoadingDevices } = useSelector(
    (state) => state.devices
  );

  const { dasDeviceInfo, gettingDasDeviceInfo } = useSelector(
    (state) => state.dasDevices
  );

  console.log({ discoveryDevice });

  useEffect(() => {
    if (!dasDeviceInfo) {
      dispatch(getDasDeviceInfo(serialNumber));
    }
  }, [dasDeviceInfo, dispatch, serialNumber]);

  useEffect(() => {
    if (
      dasDeviceInfo?.model?.model &&
      dasDeviceInfo?.model?.brand &&
      dasDeviceInfo?.model?.version
    ) {
      dispatch(
        getDeviceByModel({
          brand: dasDeviceInfo.model.brand,
          model: dasDeviceInfo.model.model,
          version: dasDeviceInfo.model.version
        })
      );
    }
  }, [
    dasDeviceInfo?.model?.brand,
    dasDeviceInfo?.model?.model,
    dasDeviceInfo?.model?.version,
    dispatch
  ]);

  const getDeviceImage = () => {
    if (
      discoveryDevice?.variants?.length > 0 &&
      dasDeviceInfo?.model?.variant?.color
    ) {
      const deviceVariant = discoveryDevice.variants.find(
        (variant) => variant.color === dasDeviceInfo.model.variant.color
      );

      if (deviceVariant?.image) {
        return deviceVariant.image;
      }
    } else if (discoveryDevice?.defaultImage) {
      return discoveryDevice.defaultImage;
    }

    return null;
  };

  if (gettingDasDeviceInfo || isLoadingDevices) return <Spinner />;

  return (
    <MainContainer>
      <Hero>
        <img className="logo" src={logo} alt="tap2" />
        {getDeviceImage() ? (
          <div className="wearable-container">
            <img src={getDeviceImage()} alt="wearable" />
          </div>
        ) : null}
        <h1>{"Welcome to TAP2!"}</h1>
        <div className="welcome-badge">
          <img src={registerIcon} alt="register" />
          <p>
            {
              "You can now set your contactless wearable up in just 3 steps. Make sure to follow the steps below to set up contactless payment."
            }
          </p>
        </div>
      </Hero>
      <Separator />
      <StepContainer>
        <StepIcon>
          <p>1</p>
        </StepIcon>
        <h2>{"Get a Curve Card!"}</h2>
        <div className="video-container">
          <video
            src="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/tap2/curve-tutorial.webm"
            playsInline
            preload="auto"
            controls
          />
        </div>
        <p>
          {
            "Curve is a digital wallet that brings all of your bank cards together in one place allowing you to choose what card connects to your wearable daily. Make sure to download their app below to get started. If you already have Curve, move straight onto the next step"
          }
        </p>
        <AppStoreLinks appStoreUrl={""} gPlayUrl={""} />
      </StepContainer>
      <StepContainer>
        <StepIcon>
          <p>2</p>
        </StepIcon>
        <h2>{"Download the TAP2 app"}</h2>
        <p>
          {
            "The TAP2 app is the hib of your wearable, you can add your curve card directly onto here and if you loose your wearable no worries, instantly remove your wearable in seconds keeping you and your information safe and secure."
          }
        </p>
        <AppStoreLinks appStoreUrl={""} gPlayUrl={""} />
      </StepContainer>
      <StepContainer>
        <StepIcon>
          <p>3</p>
        </StepIcon>
        <h2>{"In-app intructions"}</h2>
        <div className="video-container">
          <video
            src="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/tap2/curve-tutorial.webm"
            playsInline
            preload="auto"
            controls
          />
        </div>
        <p>
          {
            "Great, now follow these last in app steps to finish your wearable set up and start making payments on the go. Once you are set up there is no need to worry about doing anything else as there isn't really anything else to do."
          }
        </p>
        <AppStoreLinks appStoreUrl={""} gPlayUrl={""} />
      </StepContainer>
      <Footer>
        <a href="http://">
          <p>{"Manage-Mii"}</p>
        </a>
        <a href="http://">
          <p>{"DIGISEQ"}</p>
        </a>
        <a href="http://">
          <p>{"TAP-2"}</p>
        </a>
        <a href="http://">
          <p>{"Terms and conditions"}</p>
        </a>
      </Footer>
    </MainContainer>
  );
};

export default Tap2Promo;
