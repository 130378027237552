import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { animated, useSpring } from "react-spring";

import { fetchDeviceAction } from "../../features/tap2start/tap2StartSlice";
import usePrevious from "../../hooks/usePrevious";

import BinChecker from "./BinChecker";
import DeviceStatus from "./DeviceStatus";

import { Footer, MainContainer } from "./styles/SharedStyles";
import {
  ContentSkeletonContainer,
  ImageContainer,
  Logo,
  LogoContainer,
  ProductContainer,
  ProductImageSkeletonContainer,
  SkeletonContainer,
} from "./styles/Tap2Start";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import noImage from "./img/no-image.svg";
import tap2Logo from "./img/tap2-logo.png";

const Tap2StartTap2 = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const showAllOptions = searchParams.get("showAll");
  const skipCardCheck = searchParams.get("skipCardCheck");

  const [checkAnotherBin, setCheckAnotherBin] = useState(false);

  const fadeInImageProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 }, // You can adjust the duration as needed
  });

  const dispatch = useDispatch();

  const { fetchingDevice, device } = useSelector(
    (state) => state.tap2Start
  );

  const previousFetchingDevice = usePrevious(fetchingDevice);

  useEffect(() => {
    async function effect() {
      if (previousFetchingDevice && device && device?.redirectOnCompleteUrl) {
        window.location.replace(device?.redirectOnCompleteUrl);
      }
    }

    effect();
  }, [previousFetchingDevice, device]);

  useEffect(() => {
    if (serialNumber) dispatch(fetchDeviceAction(serialNumber));
  }, [dispatch, serialNumber]);

  if (fetchingDevice || device?.redirectOnCompleteUrl)
    return (
      <MainContainer>
        <ProductImageSkeletonContainer>
          <Skeleton width={160} height={160} />
        </ProductImageSkeletonContainer>
        <ContentSkeletonContainer>
          <Skeleton height={100} />
          <SkeletonContainer>
            <Skeleton height={24} />
          </SkeletonContainer>
          <SkeletonContainer>
            <Skeleton height={24} width={250} />
          </SkeletonContainer>
          <SkeletonContainer>
            <Skeleton height={24} width={300} />
          </SkeletonContainer>
        </ContentSkeletonContainer>
      </MainContainer>
    );

  return (
    <animated.div style={fadeInImageProps}>
      <MainContainer>
        <LogoContainer>
          <Logo noImage={!device?.wearableImageURL}>
            <img src={tap2Logo} alt="tap2logo" />
          </Logo>
        </LogoContainer>
        <ProductContainer>
          <ImageContainer noImage={!device?.wearableImageURL}>
            <img
              src={device?.wearableImageURL || noImage}
              alt="product"
            />
          </ImageContainer>
        </ProductContainer>
        {(!device ||
          device?.status === "NOT_REGISTERED" ||
          checkAnotherBin) && (
          <BinChecker
            serialNumber={serialNumber}
            showAllOptions={showAllOptions}
            skipCardCheck={skipCardCheck}
          />
        )}
        {(device?.status === "REGISTERED" ||
          (device?.status === "PERSONALISED" && !checkAnotherBin)) && (
          <DeviceStatus
            serialNumber={serialNumber}
            status={device?.status}
            checkAnotherBin={() => setCheckAnotherBin(true)}
          />
        )}

        <Footer>
          <a
            href={"https://tap-2.com/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Tap2
          </a>
          <a
            href={"https://www.manage-mii.co.uk/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Manage-Mii
          </a>
          <a
            href={"https://tap-2.com/terms-conditions/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and conditions
          </a>
        </Footer>
      </MainContainer>
    </animated.div>
  );
};

export default Tap2StartTap2;
